import React from 'react'
import { useAudio } from '../../Context/AudioContext'

const Thumb = () => {
  const {
    script,
    setScript,
    thumbData,
    activeIdx,
    setActiveIdx,
    country,
  } = useAudio()

  return (
    <div className="videoselected-thumb">
      {thumbData.map((item, idx) => {
        const bestStudyDestinations = Array.isArray(item.bestStudyDestination) ? item.bestStudyDestination : [item.bestStudyDestination];
        const cheapestStudyDest = Array.isArray(item.cheapestStudyDest) ? item.cheapestStudyDest : [item.cheapestStudyDest]

        return (
          <div className="item-container" key={idx}>
            <div className="clearfix">
              <div className="float-start">
                <button
                  className={activeIdx === idx ? 'chapter-active' : ''}
                  onClick={() => {
                    setActiveIdx(idx)
                    setScript(item)
                  }}
                >
                  {item.name}
                </button>
              </div>
              {country ? (
                <div className="float-end">
                  <img src={item.CountryFlag} className="thumb-countryimage" />
                </div>
              ) : (
                <></>
              )}
            </div>
            {country ? (
              <>
                <p className="tagline elipsis" title={item.tagLine}>
                  <strong>{item.tagLine}</strong>
                </p>
                <p>
                  {item?.public_universities} Public &amp;{' '}
                  {item.private_universities} Private Universities
                </p>
              </>
            ) : (
              <>
                <p>{item.tagline}</p>
                <p><strong>STEM:</strong> {item.stem}</p>
                <p><strong>Average Salary:</strong> {item.avgstartSalary}</p>
                {/* <p>Level: {script?.ug ? 'UG' : 'PG'}</p> */}
                <p><strong>Level:</strong> {item?.ug ? 'UG' : 'PG'}</p>
                <p>
                  <strong>Top Study Destinations: </strong>
                  {bestStudyDestinations.map((destination, destinationIdx) => (
                    <span key={destinationIdx} className="d-block">
                      {destinationIdx === 0 ? (
                        <i className="fa fa-medal gold"></i>
                      ) : destinationIdx === 1 ? (
                        <i className="fa fa-medal silver"></i>
                      ) : (
                        <i className="fa fa-medal bronze"></i>
                      )}
                      {destinationIdx < bestStudyDestinations.length - 1}
                      {destination}
                    </span>
                  ))}
                </p>
                <p>
                  <strong>Cheapest Study Destinations: </strong>
                  {cheapestStudyDest.map((destination, destinationIdx) => (
                    <span key={destinationIdx} className='d-block'>
                      {destinationIdx === 0 ? (
                        <span class="pe-1">1.</span>
                      ) : destinationIdx === 1 ? (
                        <span class="pe-1">2.</span>
                      ) : (
                        <span class="pe-1">3.</span>
                      )}
                      {destinationIdx < cheapestStudyDest.length - 1}
                      {destination}
                    </span>
                  ))}
                </p>
              </>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default Thumb
