import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { url } from "../Constants";
import API from "../../Api/Api";
import { BsImageFill } from "react-icons/bs";

function EditTestimonial() {
  const [data, setData] = useState({
    user_name: "",
    user_rating: "",
    course_name: "",
    university_name: "",
    user_testimonial: "",
    user_image: "",
  });

  const Userdata = (e) => {
    const { name, value } = e.target;
    setData((newData) => {
      return {
        ...newData,
        [name]: value,
      };
    });
  };

  // create function for API call

  const { id } = useParams("");

  const getData = async () => {
    const res = await fetch(`${url}/gettestimonial/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const newData = await res.json();
    //console.log(newData)
    setData(newData);
  };

  useEffect(() => {
    getData();
  }, []);

  //  create function for updatation.

  const updateData = async (e) => {
    e.preventDefault();

    let formData = new FormData();
    for (let value in data) {
      formData.append(value, data[value]);
    }

    const res2 = await API("patch", `/edittestimonial/${id}`, formData, {
      "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
    });
    if (res2?.success) {
      swal({
        title: "Success",
        text: "Data Updated Successfully!",
        icon: "success",
        button: "Ok",
      });
      getData();
      navigation("/dashboard-testimonials");
    }
  };

  const navigation = useNavigate();
  const backButton = () => {
    navigation("/dashboard");
  };

  return (
    <div className="container admin-createpage">
      <div>
        <div className="main-heading">Edit Testimonial</div>
      </div>
      <div className="sep" />
      <div className="page-form">
        <form>
          <div className="row">
            <div className="col-3">
              <label className="form-label">
                User Name<span className="required">*</span>
              </label>
              <input
                value={data.user_name}
                name="user_name"
                onChange={Userdata}
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">
                Course Name<span className="required">*</span>
              </label>
              <input
                value={data.course_name}
                name="course_name"
                onChange={Userdata}
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">
                University Name<span className="required">*</span>
              </label>
              <input
                value={data.university_name}
                name="university_name"
                onChange={Userdata}
                type="text"
                className="form-control"
              />
            </div>
            <div className="col-3">
              <label className="form-label">
                Rating<span className="required">*</span>
              </label>
              <select
                value={data.user_rating}
                name="user_rating"
                onChange={Userdata}
                type="text"
                className="form-select"
              >
                <option>ratings</option>
                {[1, 2, 3, 4, 5].map((e) => (
                  <option>{e}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-9">
              <label className="form-label">
                Testimonial<span className="required">*</span>
              </label>
              <textarea
                value={data.user_testimonial}
                name="user_testimonial"
                onChange={Userdata}
                rows="10"
                cols="10"
                type="text"
                className="form-control"
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-3">
              <img
                src={data?.user_image}
                style={{ height: "100px", width: "100px", borderRadius: "50%" }}
                alt="profile-pic"
              />
            </div>
            <div className="col-3">
              <label className="form-label">
                Profile Image (only jpg files)<span className="required">*</span>
              </label>{" "}
              &ensp;
              <BsImageFill className="BsImageFill" />
              <input
                type="file"
                className="form-control"
                placeholder=""
                defaultValue=""
                accept="image/jpeg"
                onChange={(e) =>
                  setData({ ...data, user_image: e.target.files[0] })
                }
                name="profilePhoto"
                aria-label=""
              />
            </div>
          </div>
        </form>
        <br />
        <button
          type="submit"
          onClick={updateData}
          className="btn btn-primary-regular me-3"
        >
          Update
        </button>
        <button
          type="submit"
          onClick={backButton}
          className="btn btn-secondary-regular"
        >
          Back
        </button>
      </div>
    </div>
  );
}

export default EditTestimonial;
