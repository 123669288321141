import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import "../Assets/Styles/shortlisting.css";

import { Dropdown, DropdownButton, Button, ButtonGroup } from 'react-bootstrap';

// import { SchoolsData, countryStateCity } from "./programsData";
// import { BiSearch } from "react-icons/bi";
// import "../Assets/Styles/programs.css";
// import { Link, useNavigate } from "react-router-dom";
// import Img from "../Assets/Media/Images/nodata.png";
// import CircularProgress from "@mui/material/CircularProgress";

import { Pagination } from "react-bootstrap";
import { url } from "../Constants";
import swal from "sweetalert";
import { Link, useNavigate, useParams } from "react-router-dom";

import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import API from "../../Api/Api";

import CountriesData from "../Assets/Data/countries.json";
import courseData from "../Assets/Data/coursecounseling.json";
import StatesData from "../Assets/Data/state.json";
import university_tag from "../Assets/Data/university_tags.json";

const Shortlisting = () => {
  const hasBeenRendered = useRef(false);
  const [collegeData, setCollegeData] = useState([]);
  const [favData, setFavData] = useState([]);
  const [course, setCourse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mainSearch, setMainSearch] = useState("");
  const [filter, setFilter] = useState({
    majorName: "",
    level: "",
    collegeName: "",
    address: "",
    medianEarning1yearAfterGraduation: "",
    courseRanking: "",
    nationalRank: "",
    globalRank: "",
    ranking: "",
    usNewsHighSchoolGpa: "",
    usNewsApplicationDeadline: "",
    undergraduateApplicationFee: "",
    ugTuitionAndFees: "",
    pgTuition: "",
    pgFees: "",
    ugPercentAdmitted: "",
    admissions: "",
    applyOnline: "",
    financialAid: "",
    generalInformation: "",
    website: "",
    campusHousing: "",
    studentToFacultyRatio: "",
    city: "",
    zipcode: "",
    state: "",
    institutionalGrantsOrScholarships: "",
    degreeName: "",
    courseLink: "",
    studyMode: "",
    country: "",
    startDate: "",
    duration: "",
    academicRequirement: "",
    entryRequirement: "",
    minFee: 0,
    maxFee: 0,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [checkbox, setCheckbox] = useState({
    all: true,
    program: false,
    collegeName: false,
    level: false,
  });
  const [compareCount, setCompareCount] = useState(0);
  const navigate = useNavigate();
  function createQueryString(params) {
    const queryString = Object.keys(params)
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    return queryString ? `&${queryString}` : "";
  }

  const getCollegeDataWithFilter = async (filter) => {
    // if (mainSearch.length > 1) {
    setLoading(true);
    try {
      const response = await API(
        "get",
        `/college-shortlisting/all?page=${currentPage}&pageSize=${20}${createQueryString(
          filter
        )}`
      );
      setCollegeData(response?.data?.data);
      setTotalPages(response?.data?.total);
      if (localStorage.getItem("compareCourses")) {
        setCompareCount(
          JSON.parse(localStorage.getItem("compareCourses")).length
        );
      } else {
        setCompareCount(0);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
    // } else {
    //   swal({
    //     title: "Error",
    //     text: "Search field cannot be empty",
    //     icon: "warning",
    //   });
    // }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleMarkComplete = async (step) => {
    try {
        const response = await fetch(`${url}/user/updateCompletionSteps`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
                token: localStorage.getItem("token"),
            },
            body: JSON.stringify({ [`step${step}Complete`]: true }),
        });

        const res_json = await response.json();

        if (res_json.success) {
            swal({
                title: "Success!",
                text: "Step marked as complete!",
                icon: "success",
                button: "Ok!",
            });
            navigate('/userdashboard');
        } else {
            swal({
                title: "Error!",
                text: res_json.message,
                icon: "error",
                button: "Ok!",
            });
        }
    } catch (error) {
        console.error("Error marking step as complete:", error);
    }

    try {
        // Assuming you're fetching or have access to user data
        const firstName = localStorage.getItem('firstName') || 'User';
        const email = localStorage.getItem('email');
        const stepCompleted = step; // Replace with the actual step number if dynamic

        // Validate required data
        if (!email) {
            console.error('Email is missing');
            return;
        }

        // Construct the `userData` object
        const userData = {
            stepCompleted,
            firstName,
            email,
        };

        const response = await fetch(`${url}/user/send-completion-email`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                token: localStorage.getItem('token'), // Assuming token is stored in localStorage
            },
            body: JSON.stringify(userData),
        });

        const res_json = await response.json();

        if (res_json.success) {
            //console.log('Email sent successfully');
        } else {
            console.error('Failed to send email', res_json.message);
        }
    } catch (error) {
        console.error('Error sending email', error);
    }
};

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await fetch(`${url}/auth/verifyuser`, {
        method: "POST",
        mode: "cors",
        referrerPolicy: "origin-when-cross-origin",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          token: localStorage.getItem("token"),
        },
      });

      const res_json = await response.json();
      const json = res_json.data;

      localStorage.setItem(
        "courses",
        JSON.stringify(res_json?.userCourses?.userInfo?.course_of_interest)
      );
      localStorage.setItem("destination", JSON.stringify(json.studyDest));
      localStorage.setItem("level", res_json?.userCourses?.userInfo?.level);
      
      if (json?.lead_status) {
        localStorage.setItem("lead_status", json?.lead_status);
      } else {
        console.error("Lead status is not available in the response");
      }

      const leadStatus = json.lead_status;
      if (leadStatus !== "registeredplatinum") {
        swal({
          title: "Error",
          text: "You do not have access to this page. Please subscribe to Platinum Plan",
          icon: "warning",
        }).then(() => {
          navigate("/"); // Redirect to homepage
        });
      }

    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    if (hasBeenRendered.current) {
      getCollegeDataWithFilter(filter);
    }
    hasBeenRendered.current = true;
  }, [currentPage]);

  const getAllFavCourses = async () => {
    try {
      const response = await API(
        "get",
        `/shortlisting/fav-all/${localStorage.getItem("_id")}`
      );
      setFavData(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    getAllFavCourses();
  }, []);

  //   useEffect(() => {
  //     console.log(checkbox);
  //   }, [checkbox]);

  const handleCompare = (item) => {
    if (localStorage.getItem("compareCourses")) {
      const prevCourses = JSON.parse(localStorage.getItem("compareCourses"));

      if (prevCourses.find((itm) => itm?._id == item?._id)) {
        let newrr = prevCourses.filter((itm) => itm?._id != item?._id);
        localStorage.setItem("compareCourses", JSON.stringify(newrr));
        setCompareCount(newrr.length);
        return swal({
          title: "Removed",
          text: "Course removed from comparison",
          icon: "info",
        });
      }

      if (prevCourses.length < 3) {
        let newCoursesToCompare = [...prevCourses, item];
        localStorage.setItem(
          "compareCourses",
          JSON.stringify(newCoursesToCompare)
        );
        setCompareCount(newCoursesToCompare.length);
      } else {
        swal({
          title: "Error",
          text: "3 Courses already present to compare",
          icon: "error",
        });
      }
    } else {
      localStorage.setItem("compareCourses", JSON.stringify([item]));
      setCompareCount(1);
    }
  };
  let CompareColleges = JSON.parse(localStorage.getItem("compareCourses"));

  function extractKeys(objects) {
    if (objects?.length < 1) {
      return;
    }
    const keysSet = new Set();

    objects?.forEach((obj) => {
      for (const key in obj) {
        if (typeof obj[key] === "string" && key !== "_id") {
          keysSet.add(key);
        }
      }
    });

    return Array.from(keysSet);
  }

  function splitCamelCaseToWords(text) {
    return text
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
      .trim();
  }

  function downloadCSV(arrayOfObjects, fileName = "data.csv") {
    if (!arrayOfObjects || !arrayOfObjects.length) {
      console.error("Invalid input: array of objects is required");
      return;
    }

    const csvHeaders = Object.keys(arrayOfObjects[0]);
    const csvRows = arrayOfObjects.map((obj) =>
      csvHeaders
        .map((header) => {
          let cell = obj[header];

          if (typeof cell === "object" && cell !== null) {
            cell = JSON.stringify(cell);
          }

          if (typeof cell === "string" && cell.includes(",")) {
            cell = `"${cell.replace(/"/g, '""')}"`;
          }

          return cell;
        })
        .join(",")
    );

    const csvContent = [csvHeaders.join(","), ...csvRows].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);

    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const AddToFav = async (courseid) => {
    try {
      await API("post", `/shortlisting/add-to-fav`, {
        user: localStorage.getItem("_id"),
        course: courseid,
      });
      swal({
        text: "Add to Favourite courses!",
        icon: "success",
      });
      getAllFavCourses();
    } catch (error) {}
  };

  const RemoveFav = async (courseid) => {
    try {
      await API("post", `/shortlisting/remove-fav`, {
        user: localStorage.getItem("_id"),
        course: courseid,
      });
      swal({
        text: "Removed from Favourite courses!",
        icon: "success",
      });
      getAllFavCourses();
    } catch (error) {}
  };

  const handleCreateApplication = (country, uni, course) => {
    navigate(
      `/dashboard/user/createapplication/${localStorage.getItem(
        "_id"
      )}?edit=true&country=${country}&universityName=${uni}&courseName=${course}`
    );
  };

  const createApplication = async (
    country,
    intake,
    universityName,
    courseName
  ) => {
    const response = await fetch(`${url}/application/new`, {
      method: "POST",
      mode: "cors",
      referrerPolicy: "origin-when-cross-origin",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        token: localStorage.getItem("token"),
      },
      body: JSON.stringify({ intake, universityName, courseName, country }),
    });

    const res_json = await response.json();
    //console.log(res_json)

    if (res_json.existing === true && res_json.data.submitted === true) {
      swal({
        title: "Error",
        text: "Application Already Submitted",
        icon: "error",
        button: "Ok!",
      });
      navigate("/shortlisting");
    } else {
      sessionStorage.setItem("applicationId", res_json.data.applicationId);
      navigate("/applicationprofile");
    }
  };

  const generatePages = (n) => {
    const totalPages = n;
    const paginationArray = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        paginationArray.push(i);
      }
    } else {
      if (currentPage <= 3) {
        paginationArray.push(1, 2, 3, 4, "...", totalPages);
      } else if (currentPage >= totalPages - 2) {
        paginationArray.push(
          1,
          "...",
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages
        );
      } else {
        paginationArray.push(
          1,
          "...",
          currentPage - 1,
          currentPage,
          currentPage + 1,
          "...",
          totalPages
        );
      }
    }

    return paginationArray;
  };

  function findValue(array) {
    for (let i = 0; i < array.length; i++) {
      if (typeof array[i] !== "undefined") {
        return array[i];
      }
    }
    return "-";
  }

  const renderTuitionFee = (item) => {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    if (filter.domestic && item.level.toLowerCase().includes("mast")) {
      return (
        "Domestic PG Tuition Fee: " +
        findValue([
          item["pgInStateTuition&Fees"],
          item?.pgInStateTuition,
          formatter.format(item?.fee || 0),
        ])
      );
    }
    if (filter.domestic && item.level.toLowerCase().includes("bach")) {
      return (
        "Domestic UG Tuition Fee: " +
        findValue([
          item["ugInStateTuition&Fees"],
          item["ugInStateTuitionAndFees"],
          item?.ugInStateTuition,
          formatter.format(item?.fee || 0),
        ])
      );
    }
    if (item.level.toLowerCase().includes("bach")) {
      return (
        "UG Tuition Fee: " +
        findValue([
          item?.ugTuitionAndFees,
          item["ugOutOfStateTuition&Fees"],
          item?.ugOutStateTuition,
          item["ugInStateTuition&Fees"],
          item["ugInStateTuitionAndFees"],
          item?.ugInStateTuition,
          formatter.format(item?.fee || 0),
        ])
      );
    }
    if (item.level.toLowerCase().includes("mast")) {
      return (
        "PG Tuition Fee: " +
        findValue([
          item["pgTuition"],
          item?.pgOutStateTuition,
          item["pgInStateTuition&Fees"],
          item?.pgInStateTuition,
          formatter.format(item?.fee || 0),
        ])
      );
    }

    return "Tution Fee: " + formatter.format(item?.fee || 0);
  };

  const clearCompare = () => {
    localStorage.removeItem("compareCourses");
    setCompareCount(0);
    swal({
      title: "Cleared",
      text: "All compared courses have been removed",
      icon: "info",
    });
  };

  return (
    <>
      <div className="body-content no-filterpanel">
        <div className="body-panel d-flex justify-content-between">
          <div className="body-container width-100">
            <div className="page-content">
              <div className="row">
                <div className="col d-flex justify-content-between">
                  <h1 className="mb-3">Shortlisting</h1>
                  <div>
                    <Button
                      variant="primary-regular blinking-btn me-2 float-end"
                      onClick={() => handleMarkComplete(5)} // Replace 5 with the appropriate step number
                      className="ms-2"
                    >
                      Mark Step as Complete
                    </Button>
                    <button onClick={() => { navigate('/shortlisting-user/' + localStorage.getItem('_id')) }} className="widget-cta-page-nopos-noresponsive float-end mb-3 me-2">
                      View Shortlistings
                    </button>
                  </div>
                </div>
              </div>
              <div className="page-filter">
                <div className="row">
                  <div className="col">
                    <div class="form-floating">
                      <input
                        type="email"
                        class="form-control"
                        id="floatingInput"
                        onChange={(e) => {
                          setMainSearch(e.target.value);
                          setFilter({
                            ...filter,
                            majorName: e.target.value,
                            degreeName: e.target.value,
                            courseName: e.target.value,
                            collegeName: e.target.value,
                            // level: e.target.value,
                          });
                        }}
                        placeholder=""
                      />
                      <label for="floatingInput">
                        Search for program, institution or any keyword
                      </label>
                    </div>
                    {/* <div>
                      <input
                        type="checkbox"
                        onClick={(e) =>
                          setCheckbox({
                            ...checkbox,
                            program: e.target.checked,
                          })
                        }
                      />{" "}
                      Program / Course Name
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        onClick={(e) =>
                          setCheckbox({
                            ...checkbox,
                            collegeName: e.target.checked,
                          })
                        }
                      />{" "}
                      University or College Name
                    </div>
                    <div>
                      <input
                        type="checkbox"
                        onClick={(e) =>
                          setCheckbox({
                            ...checkbox,
                            level: e.target.checked,
                          })
                        }
                      />{" "}
                      Level
                    </div> */}
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-2">
                  <div>
                    <button
                      type="submit"
                      className="btn btn-primary-regular"
                      onClick={() => {
                        if (mainSearch.length > 1) {
                          getCollegeDataWithFilter(filter);
                        } else {
                          swal({
                            title: "Error",
                            text: "Search field must have atleast 2 characters",
                            icon: "warning",
                          });
                        }
                      }}
                    >
                      Search
                    </button>
                  </div>
                  <div>

                  <DropdownButton
      as={ButtonGroup}
      id="dropdown-basic-button"
      title={`Download Favorites (${favData?.length || 0})`}
      disabled={favData?.length < 1}
      variant="primary me-2"
    >
      {favData.map((item, index) => (
        <Dropdown.ItemText className="dropdown-item" key={index}>
          {item.collegeName} &nbsp;
          <span
            className="text-success"
            style={{ cursor: 'pointer' }}
            onClick={() => downloadCSV([item], `Shortlisting.csv`)}
          >
            <i className="fa fa-download"></i>
          </span>
          &nbsp;|&nbsp;
          <span
            className="text-danger"
            style={{ cursor: 'pointer' }}
            onClick={() => RemoveFav(item?._id)}
          >
            <i className="fa fa-trash"></i>
          </span>
        </Dropdown.ItemText>
      ))}
      {favData.length > 1 && (
        <>
          <Dropdown.Divider />
          <Dropdown.Item
            onClick={() => downloadCSV(favData, 'AllFavorites.csv')}
          >
            Download All
          </Dropdown.Item>
        </>
      )}
    </DropdownButton>

                    {/* <button
                      className="btn btn-primary-regular me-2"
                      onClick={() => downloadCSV(favData, "Shortlisting.csv")}
                      disabled={favData?.length < 1}
                    >
                      Download Favorites ({favData?.length || 0}) &nbsp;{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="20"
                        height="20"
                        viewBox="0 0 48 48"
                      >
                        <path
                          fill="#169154"
                          d="M29,6H15.744C14.781,6,14,6.781,14,7.744v7.259h15V6z"
                        ></path>
                        <path
                          fill="#18482a"
                          d="M14,33.054v7.202C14,41.219,14.781,42,15.743,42H29v-8.946H14z"
                        ></path>
                        <path
                          fill="#0c8045"
                          d="M14 15.003H29V24.005000000000003H14z"
                        ></path>
                        <path
                          fill="#17472a"
                          d="M14 24.005H29V33.055H14z"
                        ></path>
                        <g>
                          <path
                            fill="#29c27f"
                            d="M42.256,6H29v9.003h15V7.744C44,6.781,43.219,6,42.256,6z"
                          ></path>
                          <path
                            fill="#27663f"
                            d="M29,33.054V42h13.257C43.219,42,44,41.219,44,40.257v-7.202H29z"
                          ></path>
                          <path
                            fill="#19ac65"
                            d="M29 15.003H44V24.005000000000003H29z"
                          ></path>
                          <path
                            fill="#129652"
                            d="M29 24.005H44V33.055H29z"
                          ></path>
                        </g>
                        <path
                          fill="#0c7238"
                          d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,15.681v16.638C24,33.247,23.247,34,22.319,34z"
                        ></path>
                        <path
                          fill="#fff"
                          d="M9.807 19L12.193 19 14.129 22.754 16.175 19 18.404 19 15.333 24 18.474 29 16.123 29 14.013 25.07 11.912 29 9.526 29 12.719 23.982z"
                        ></path>
                      </svg>
                    </button> */}
                    <button
                      className="btn btn-primary-regular"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                      disabled={!(compareCount >= 2)}
                    >
                      View Compare ({compareCount})
                    </button>
                    <button
                      className="btn btn-link ms-2"
                      onClick={clearCompare}
                      disabled={compareCount === 0}
                    >
                      Clear Compare
                    </button>
                  </div>
                </div>
              </div>
              <>
                <div>
                  <div className="mt-3 float-end"></div>
                  <div
                    class="modal fade"
                    id="exampleModal"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div class="modal-dialog modal-xl">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h1 class="modal-title fs-5" id="exampleModalLabel">
                            View Comparison
                          </h1>
                          <button
                            type="button"
                            class="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div class="modal-body">
                          <div class="table-responsive">
                            <table class="table text-center">
                              <thead>
                                <tr>
                                  <th></th>
                                  {CompareColleges?.length > 0 && (
                                    <th>{CompareColleges[0]?.collegeName}</th>
                                  )}

                                  {CompareColleges?.length > 1 && (
                                    <th>{CompareColleges[1]?.collegeName}</th>
                                  )}

                                  {CompareColleges?.length > 2 && (
                                    <th>{CompareColleges[2]?.collegeName}</th>
                                  )}
                                </tr>
                              </thead>
                              <tbody>
                                {extractKeys(CompareColleges)?.map((key) => {
                                  // console.log(key);
                                  return (
                                    <tr key={key}>
                                      <th scope="row" class="text-start">
                                        {splitCamelCaseToWords(key)}
                                      </th>
                                      {CompareColleges?.length > 0 && (
                                        <td>
                                          {CompareColleges[0][key] && (CompareColleges[0][key].includes("www.") ||
                                          CompareColleges[0][key].includes("http")) ? (
                                            <Link
                                              target="_blank"
                                              rel="external"
                                              to={
                                                !CompareColleges[0][key].includes("http")
                                                  ? "https://" + CompareColleges[0][key]
                                                  : CompareColleges[0][key]
                                              }
                                            >
                                              {CompareColleges[0][key]}{" "}
                                            </Link>
                                          ) : (
                                            CompareColleges[0][key] || "N/A"
                                          )}
                                        </td>
                                      )}
                                      {CompareColleges?.length > 1 && (
                                        <td>
                                          {CompareColleges[1][key] && CompareColleges[1][key].includes("www.") ||
                                          CompareColleges[1][key] && CompareColleges[1][key].includes("http") ? (
                                            <Link
                                              target="_blank"
                                              rel="external"
                                              to={
                                                !CompareColleges[1][key].includes("http")
                                                  ? "https://" + CompareColleges[1][key]
                                                  : CompareColleges[1][key]
                                              }
                                            >
                                              {CompareColleges[1][key]}{" "}
                                            </Link>
                                          ) : (
                                            CompareColleges[1][key] || "N/A"
                                          )}
                                        </td>
                                      )}
                                      {CompareColleges?.length > 2 && (
                                        <td>
                                          {CompareColleges[2][key] && CompareColleges[2][key].includes("www.") ||
                                          CompareColleges[2][key] && CompareColleges[2][key].includes("http") ? (
                                            <Link
                                              target="_blank"
                                              rel="external"
                                              to={
                                                !CompareColleges[2][key].includes("http")
                                                  ? "https://" + CompareColleges[2][key]
                                                  : CompareColleges[2][key]
                                              }
                                            >
                                              {CompareColleges[2][key]}{" "}
                                            </Link>
                                          ) : (
                                            CompareColleges[2][key] || "N/A"
                                          )}
                                        </td>
                                      )}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="clearfix"></div>
                <div className="shortlisting-results d-flex">
                  <div className="filter-container-inside">
                    <div className="row">
                      <div className="col mb-3">
                        <div className="form-floating">
                          <Select
                            className="app-multiSelect"
                            isMulti
                            value={filter?.country?.split(",")?.map(
                              (op, i) =>
                                op && {
                                  value: op,
                                  label: op,
                                }
                            )}
                            options={CountriesData.map((op, i) => ({
                              value: op.name,
                              label: op.name,
                            }))}
                            onChange={(selectedOptions) => {
                              const newData = selectedOptions
                                ? selectedOptions.map((option) => option.value)
                                : [];
                              setFilter((prevData) => ({
                                ...prevData,
                                country: newData.join(","),
                              }));
                            }}
                            placeholder="Countries"
                          />
                          <label for="floatingSelect">
                            Select your Study Destination
                            <span className="required">*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="form-floating">
                          <Select
                            className="app-multiSelect"
                            isMulti
                            value={filter.stateTemp?.split(",")?.map(
                              (op, i) =>
                                op && {
                                  value: op,
                                  label: op,
                                }
                            )}
                            options={StatesData.find((itm) =>
                              filter?.country.includes(itm?.country_name)
                            )?.states?.map((op, i) => ({
                              value: op.state_name,
                              label: op.state_name,
                            }))}
                            onChange={(selectedOptions) => {
                              const newData = selectedOptions
                                ? selectedOptions.map((option) => option.value)
                                : [];
                              setFilter((prevData) => ({
                                ...prevData,
                                address: newData.join(","),
                                stateTemp: newData.join(","),
                              }));
                            }}
                            placeholder="States"
                          />

                          <label for="floatingSelect">
                            State<span className="required">*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col mb-3">
                        <div className="form-floating">
                          <Select
                            className="app-multiSelect"
                            isMulti
                            // value={filter.majorName?.split(",")?.map(
                            //   (op, i) =>
                            //     op && {
                            //       value: op,
                            //       label: op,
                            //     }
                            // )}
                            options={Object?.keys(courseData).map((op, i) => ({
                              value: op,
                              label: op,
                            }))}
                            onChange={(selectedOptions) => {
                              const newData = selectedOptions
                                ? selectedOptions.map((option) => option.value)
                                : [];

                              setFilter((prevData) => ({
                                ...prevData,
                                majorName: newData.join(","),
                                degreeName: newData.join(","),
                                courseName: newData.join(","),
                                subjectMultiSearch: true,
                              }));
                              let arr = [];
                              newData.map((itm) =>
                                arr.push(...courseData[itm])
                              );
                              setCourse(arr);
                            }}
                            placeholder="Subject"
                          />
                          <label for="floatingSelect">
                            Select Subject<span className="required">*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col mb-3">
                        <div className="form-floating">
                          <Select
                            className="app-multiSelect"
                            isMulti
                            options={course?.map((op, i) => ({
                              value: op?.name,
                              label: op?.name,
                            }))}
                            onChange={(selectedOptions) => {
                              const newData = selectedOptions
                                ? selectedOptions.map((option) => option.value)
                                : [];

                              setFilter((prevData) => ({
                                ...prevData,
                                majorName: newData.join(","),
                                degreeName: newData.join(","),
                                courseName: newData.join(","),
                                subjectMultiSearch: true,
                                courseMultiSearch: true,
                              }));
                            }}
                            placeholder="Course"
                          />
                          <label for="floatingSelect">
                            Select Course<span className="required">*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="form-floating">
                          <select
                            className="form-select"
                            id="floatingSelect"
                            aria-label="Floating label select example"
                            // disabled={mainSearch.length < 1 ? true : false}
                            onChange={(e) =>
                              setFilter({ ...filter, level: e.target.value })
                            }
                          >
                            <option value="" selected>
                              Select Level
                            </option>
                            <option value="Bach">UG</option>
                            <option value="Mast">PG</option>
                            {/* <option value="3">Three</option> */}
                          </select>
                          <label for="floatingSelect">
                            Select Level<span className="required">*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col">
                        Annual Tutition Fee Type
                        <div className="fees-switch">
                          International
                          <label className="switch">
                            <input
                              type="checkbox"
                              // disabled={mainSearch.length < 1 ? true : false}
                              onChange={(e) =>
                                setFilter({
                                  ...filter,
                                  domestic: e.target.checked,
                                })
                              }
                            />
                            <span className="slider"></span>
                          </label>
                          Domestic
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col">
                        <div className="mb-4">
                          Tutition Fee Range ({filter.minFee} - {filter.maxFee})
                        </div>
                        <RangeSlider
                          min={0}
                          max={100000}
                          // disabled={mainSearch.length < 1 ? true : false}
                          onInput={(e) => {
                            setFilter({
                              ...filter,
                              minFee: e[0],
                              maxFee: e[1],
                            });
                          }}
                        />
                        <div class="form-check mt-3">
                          <input
                            type="checkbox"
                            // disabled={mainSearch.length < 1 ? true : false}
                            className="form-check-input"
                          />{" "}
                          Doesn't Matter
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col">
                        <button
                          type="submit"
                          className="btn btn-primary mt-2"
                          onClick={() => {
                            getCollegeDataWithFilter(filter);
                            handlePageChange(1);
                          }}
                        >
                          Filter
                        </button>
                      </div>
                    </div>
                  </div>
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    <ul>
                      {collegeData?.length > 0 ? (
                        collegeData?.map((item, index) => (
                          <>
                          <li key={item?._id} className="shortlisting-record d-flex">
                            <ul>
                              <li className="shortlisting-name">
                                <span>
                                  <b>
                                    {item?.degreeName ||
                                      item?.courseName ||
                                      item?.majorName}
                                  </b>{" "}
                                </span>
                                <p><strong>{item?.collegeName}</strong></p>
                                <p>{item?.website}</p>
                              </li>
                              <li>
                                  <span className="tags tag-pouplar">
                                    {item?.state && item?.state + ","}{" "}
                                    {item?.country}
                                  </span>
                                  <span className="tag-value">
                                    {university_tag
                                      .find(
                                        (un) =>
                                          un.name.includes(item?.collegeName) &&
                                          item.majorName.includes(un?.majorName)
                                      )
                                      ?.tag
                                      ?.split(' ')
                                      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
                                      .join(' ')}
                                  </span>
                                </li>
                              <li>{renderTuitionFee(item)}</li>
                              <li>{item?.entryRequirement}</li>
                              {item?.globalRank !== "Unranked" &&
                                item?.globalRank && (
                                  <li>{item?.nationalRank}</li>
                                )}

                              {item?.globalRank !== "Unranked" &&
                                item?.globalRank && <li>{item?.globalRank}</li>}

                              <li>
                                <button
                                  className="btn btn-link p-0 float-start"
                                  onClick={() => handleCompare(item)}
                                >
                                  {localStorage.getItem("compareCourses")
                                    ? JSON.parse(
                                        localStorage.getItem("compareCourses")
                                      ).find((itm) => itm?._id == item?._id)
                                      ? " Remove from Compare"
                                      : " Add to Compare"
                                    : "Add to Compare"}
                                </button>{" "}
                                <button
                                  className="btn btn-link p-0 float-end"
                                  onClick={() =>
                                    createApplication(
                                      item?.country,
                                      0,
                                      item?.collegeName,
                                      item?.majorName ||
                                        item?.degreeName ||
                                        item?.courseName
                                    )
                                  }
                                >
                                  Create Application
                                </button>
                              </li>
                            </ul>
                            <span className="fav">
                              {favData.find(
                                (itm) => itm._id == item?._id
                              ) ? (
                                <i
                                  className="fa fa-bookmark"
                                  onClick={() => RemoveFav(item?._id)}
                                ></i>
                              ) : (
                                <i
                                  className="fa fa-bookmark-o"
                                  onClick={() => AddToFav(item?._id)}
                                ></i>
                              )}
                            </span>
                          </li>
                          
                        </>
                        ))
                      ) : (
                        <p className="required p-2">No Data Found</p>
                      )}
                    </ul>
                  )}
                </div>
              </>

              <Pagination className="d-flex justify-content-center mt-5 mb-5">
                <Pagination.Prev
                  disabled={currentPage === 1}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Prev
                </Pagination.Prev>
                {generatePages(totalPages)?.map((itm) => (
                  <Pagination.Item
                    className={itm == currentPage ? "active" : ""}
                    onClick={() => {
                      if (itm !== "...") {
                        handlePageChange(itm);
                      }
                    }}
                  >
                    {itm}
                  </Pagination.Item>
                ))}

                <Pagination.Next
                  disabled={currentPage === totalPages || totalPages <= 1}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </Pagination.Next>
              </Pagination>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Shortlisting;
