import React, { useEffect, useState } from "react";
import { url } from "../Constants";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import swal from "sweetalert";
import FileSaver from "file-saver";

const UserFiles = () => {
  const { id } = useParams("");
  const [indata, setIndata] = useState({});
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const getData = async (id) => {
    const res = await fetch(`${url}/user/files/admin/get`, {
      mode: "cors",
      method: "POST",
      referrerPolicy: "origin-when-cross-origin",
      headers: {
        token: authToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ userId: id }),
    });

    const json = await res.json();
    // console.log(json)

    if (res.status === 200) {
      setIndata(json.data);
    } else {
      alert("no data available");
    }
    setIsLoading(false);
  };

  const updateStatus = async (docId, fileId, status, message = "") => {
    const res = await fetch(`${url}/user/files/admin/status`, {
      mode: "cors",
      method: "POST",
      referrerPolicy: "origin-when-cross-origin",
      headers: {
        token: authToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        userId: id,
        documentId: docId,
        fileId: fileId,
        status: status,
        message: message,
      }),
    });

    const json = await res.json();

    if (json.success === true) {
      swal({
        title: "Success",
        text: "Status updated",
        icon: "success",
        button: "Ok",
      });
    } else {
      swal({
        title: "Error",
        text: json.message,
        icon: "error",
        button: "Ok",
      });
    }

    await getData(id);
  };

  const deleteFile = async (docId, fileId) => {
    const res = await fetch(`${url}/user/files/admin/delete`, {
      mode: "cors",
      method: "POST",
      referrerPolicy: "origin-when-cross-origin",
      headers: {
        token: authToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        userId: id,
        documentId: docId,
        fileId: fileId,
      }),
    });

    const json = await res.json();

    if (json.success === true) {
      swal({
        title: "Success",
        text: "File Deleted",
        icon: "success",
        button: "Ok",
      });
    } else {
      swal({
        title: "Error",
        text: json.message,
        icon: "error",
        button: "Ok",
      });
    }

    await getData(id);
  };

  const getDocumentName = (inputString) => {
    const regex = /_(.*?)_/;
    const matches = inputString.split('@')[1].match(regex);
    if (matches && matches.length >= 2) {
      return matches[1];
    } else {
      return ""; // Return an empty string if there are not enough matches
    }
  };

  useEffect(() => {
    getData(id);
  }, [id]);

  return (
    <div className="body-content no-filterpanel">
      <div className="body-panel">
        <div className="body-container">
        <div className="main-heading">User Files</div>
          {isLoading && <CircularProgress />}
          {!isLoading &&
            indata !== undefined &&
            indata !== null &&
            Object.keys(indata).map((id) => (
              <div key={id} className="grid-container mb-4">
                {/* {console.log(indata[id][0].uploadName)} */}
                <p><strong>{getDocumentName(indata[id][0].uploadName)}</strong></p>

                <ul className="list-detail list-header" id="list-header">
                  <li className="list-width-10">Original Name</li>
                  {/* <li className="list-width-10">Upload Name</li> */}
                  <li className="list-width-10">Status</li>
                  <li className="list-width-10">Size</li>
                  <li className="list-width-10">Link</li>
                  <li className="list-width-10"></li>
                  <li className="list-width-10"></li>
                  <li className="list-width-10"></li>
                </ul>

                <ul className="list grid-list">
                  {indata[id].map((item) => (
                    <li className="main-list">
                      <ul className="list-detail list">
                        <li className="list-width-10 file-name">{item.originalName}</li>
                        {/* <li className="list-width-10">
                                                {item.uploadName}
                                            </li> */}
                        <li className="list-width-10">{item.status}</li>
                        <li className="list-width-10">
                          {item.size !== undefined
                            ? `${parseFloat(item.size / 1048576).toFixed(2)} MB`
                            : ""}
                        </li>
                        <li className="list-width-10">
                          <Link target="_blank" to={`/view-file?url=${item?.link}`}>View File</Link>
                          <p
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              FileSaver.saveAs(item.link, item?.originalName);
                            }}
                          >
                            Download File
                          </p>
                        </li>
                        <li className="list-width-10">
                          <button
                            className="btn btn-primary"
                            target="_blank"
                            onClick={() => {
                              updateStatus(id, item._id, "approved");
                            }}
                            disabled={item.status === "approved"}
                          >
                            Approve
                          </button>
                        </li>
                        <li className="list-width-10">
                          <button
                            className="btn btn-primary"
                            target="_blank"
                            onClick={() => {
                              swal({
                                title: "Enter the reject message",
                                // text: "Type something below:",
                                content: "input",
                                buttons: {
                                  cancel: true,
                                  confirm: true,
                                },
                              }).then((value) => {
                                if (value) {
                                  updateStatus(id, item._id, "rejected", value);
                                }
                              });
                            }}
                            disabled={item.status === "rejected"}
                          >
                            Reject
                          </button>
                          {item.status === "rejected" && item.rejectMessage && (
                            <div className="small-text"><span>Reason: </span><p className="text-error subscription-plan-name mb-0">{item.rejectMessage}</p></div>
                          )}
                        </li>
                        <li className="list-width-10">
                          <button
                            className="btn btn-primary"
                            target="_blank"
                            onClick={() => {
                              swal({
                                title: "Are you sure?",
                                text: "Once deleted, you will not be able to recover this file",
                                icon: "warning",
                                buttons: true,
                                dangerMode: true,
                              }).then((willDelete) => {
                                if (willDelete) {
                                  deleteFile(id, item._id);
                                }
                              });
                            }}
                          >
                            Delete
                          </button>
                        </li>
                      </ul>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          {!isLoading && Object.keys(indata).length === 0
            ? "No Files Found"
            : ""}
          <br />
          <button
            className="btn btn-primary-regular"
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserFiles;