import React from 'react';
import { useNavigate } from 'react-router-dom'

const BankAccount = () => {

    const navigate = useNavigate();

    return (
        <>
                <div>
            <div className="body-content no-filterpanel">
            <div className="body-panel d-flex justify-content-between">
                <div className="body-container width-100">
                    <div className="row">
                        <div className="col">
                            <div className="d-flex justify-content-between mt-3">
                                <div className="page-content">
                                    <h1 className="mb-3">Bank Account</h1>
                                    {/* <a href="https://drive.google.com/file/d/1F2I8_EUOmWajePgOCz65QNGRfx58ll7R/view?usp=sharing" target="_blank" className="link-transcript">
                                        Download Transcript
                                    </a> */}
                                </div>
                                <div>
                                    <button onClick={() => { navigate('/userdashboard') }} className="widget-cta-page-nopos float-end mb-3">
                                        <i className='fa fa-home'></i> Back to Dashboard
                                    </button>
                                </div>
                                {/* <div className="video-container ms-5">
                                    <iframe
                                        src="https://www.youtube.com/embed/SBYcffiAXQQ"
                                        title="YouTube video player"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen=""
                                        width={500}
                                        height={250}
                                        frameBorder={0}
                                    />
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col bg-white p-4">
                        <table className="">
                            <tbody>
                                <tr>
                                <td>
                                    <strong>Open Your US Account Before Arrival—No SSN Needed. Enjoy No Fees, Easy Access, and Family Peace of Mind.</strong>
                                    <ul>
                                    <li>Open an account from outside the US</li>
                                    <li>Sign up before or after you arrive with your US or non-US address and phone number.</li>
                                    <li>Save on international transfers to your bank account</li>
                                    <li>Get peace of mind with no annual fee and no international transaction fees.</li>
                                    <li>Earn 1% cashback and build US credit history</li>
                                    <li>We charge $0 inbound wire transfer fees when you move money into your bank account.</li>
                                    <li>The Adro World Mastercard® can help boost your credit score, no SSN required.</li>
                                    <li>Credit card</li>
                                    <li>2 accounts: checking + savings</li>
                                    <li>Enjoy no monthly fees, 2 free withdrawals per month from any ATM1, and easy access to your money when you need it.</li>
                                    </ul>
                                    <a href='http://secure.joinadro.com/signup?referralCode=Rise' target='_blank' className='btn-secondary px-2 py-1'>Open Now</a>
                                </td>
                                <td><img src="https://secure.joinadro.com/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Flogo_black.aa09a937.png&w=640&q=75" /></td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <button onClick={()=>{navigate('/recruitment')}} className="widget-cta-page float-end mx-4">Explore Job Options</button>
            <div className="footer-links p-5">
                <button
                    type="button"
                    className="footer-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#disclaimerModal"
                >
                    Disclaimer
                </button>
                <button
                    type="button"
                    className="footer-btn"
                    data-bs-toggle="modal"
                    data-bs-target="#TnCModal"
                >
                    Terms &amp; Conditions
                </button>
                <div
                    className="modal fade"
                    id="disclaimerModal"
                    tabIndex={-1}
                    aria-labelledby="disclaimerModal"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="disclaimerModal">
                                    Disclaimer
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="TnCModal"
                    tabIndex={-1}
                    aria-labelledby="TnCModal"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="TnCModal">
                                    Terms &amp; Conditions
                                </h1>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                />
                            </div>
                            <div className="modal-body">
                                <p>
                                    If you have any questions or concerns regarding the Portal, please
                                    contact our support team at{" "}
                                    <a href="mailto:contact@risehighereducation.com">
                                        contact@risehighereducation.com
                                    </a>
                                    .
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </>
    );
}
        
export default BankAccount;